import { Document } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { useMemo } from 'react';
import RichTextBase from '@/components/richTextBase';
import { removeEmptyLastParagraph } from '@/utils/removeEmptyLastParagraph';
import { OPTIONS_WITH_EMBEDDED_ENTRY } from './options';

const RichText = ({
  richText,
  className,
  options,
}: {
  richText: Document;
  options?: Options;
  className?: string;
}) => {
  const processedRichText = useMemo(
    () => removeEmptyLastParagraph(richText),
    [richText],
  );
  return (
    <RichTextBase
      className={className}
      richText={processedRichText}
      options={options ?? OPTIONS_WITH_EMBEDDED_ENTRY}
    />
  );
};

export default RichText;
