import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { cn } from '@/lib/tailwind';
import {
  options as defaultOptions,
  normalizeListItemForRichTextOptions,
} from './options';

const RichTextBase = ({
  richText,
  options,
  className,
}: {
  richText: Document;
  options?: Options;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'space-y-4 prose-p:min-h-[1em]',
        'prose-ul:[list-style:initial] prose-ol:[list-style:decimal] prose-ul:ps-[1.4em] prose-ol:ps-[1.4em] prose-ul:space-y-4 prose-ol:space-y-4',
        className,
      )}
    >
      {documentToReactComponents(
        richText,
        normalizeListItemForRichTextOptions(options ?? defaultOptions),
      )}
    </div>
  );
};

export default RichTextBase;
