import Icon from '@/components/Icon';
import { Button as ButtonComponent } from '@/components/ui/button';
import { CtaData } from '@/types';

const Button = ({ icon, label, variant, className }: CtaData) => {
  return (
    <ButtonComponent variant={variant} className={className} asChild>
      <div>
        {icon ? <Icon icon={icon.name} className="h-4 w-4" /> : null}
        <span>{label}</span>
      </div>
    </ButtonComponent>
  );
};

export default Button;
