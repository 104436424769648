import { cn } from '@/lib/tailwind';

const TableHeaderCell = ({ children }: { children: React.ReactNode }) => {
  return (
    <td
      className={cn(
        'bg-deepSkyBlue text-white relative align-top',
        'border-r border-b first:border-l border-lightGrey',
        'text-mobile-sm/body-3-bold tablet-sm:text-tablet-sm/heading-6 desktop-lg:text-desktop-large/body-2-bold',
        'p-4 desktop-lg:py-5',
      )}
    >
      {children}
    </td>
  );
};

export default TableHeaderCell;
