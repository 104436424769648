import { cn } from '@/lib/tailwind';

const TableCell = ({ children }: { children: React.ReactNode }) => {
  return (
    <td
      className={cn(
        'p-4 desktop-lg:py-6 align-top relative border-r border-b first:border-l border-lightGrey',
        'text-mobile-sm/body-4 tablet-sm:text-tablet-sm/body-4 desktop-lg:text-desktop-large/body-3',
        'space-y-4',
      )}
    >
      {children}
    </td>
  );
};

export default TableCell;
