import React, { useMemo } from 'react';

const Iframe = ({
  iFrameCode,
  className,
}: {
  iFrameCode: string;
  className?: string;
}) => {
  const iFrameHtml = useMemo(
    () => ({
      __html: iFrameCode,
    }),
    [iFrameCode],
  );

  return <div className={className} dangerouslySetInnerHTML={iFrameHtml} />;
};

export default Iframe;
