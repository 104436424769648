import IframeComponent from '@/components/Iframe';
import { cn } from '@/lib/tailwind';

const Iframe = ({ iFrameCode }: { iFrameCode: string }) => {
  const isVideo =
    iFrameCode.includes('youtube') ||
    iFrameCode.includes('vimeo') ||
    iFrameCode.includes('video');
  const isPodcast = !isVideo && iFrameCode.includes('html5-player');

  return (
    <IframeComponent
      iFrameCode={iFrameCode}
      className={cn(
        'relative w-full',
        isPodcast && 'h-[90px]',
        isVideo &&
          'aspect-video h-auto [&>iframe]:absolute [&>iframe]:h-full [&>iframe]:w-full [&>iframe]:inset-0',
      )}
    />
  );
};

export default Iframe;
