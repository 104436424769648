import { Options, RenderNode } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { options as defaultOptions } from '@/components/richTextBase/options';
import BoxContainer from '../widgets/boxContainer';
import { EmbeddedEntry } from './components/EmbeddedEntry';
import { BOX_CONTAINER_CONTENT_TYPE } from './constants';

const RENDER_NODE_OPTIONS_WITH_EMBEDDED_ENTRY: RenderNode = {
  [BLOCKS.EMBEDDED_ENTRY]: node => {
    if (!node) return null;

    const { target } = node?.data || {};
    if (target?.contentType === BOX_CONTAINER_CONTENT_TYPE) {
      return <BoxContainer {...target} />;
    }

    return <EmbeddedEntry node={node} />;
  },
};

export const OPTIONS_WITH_EMBEDDED_ENTRY: Options = {
  ...defaultOptions,
  renderNode: {
    ...defaultOptions.renderNode,
    ...RENDER_NODE_OPTIONS_WITH_EMBEDDED_ENTRY,
  },
};
