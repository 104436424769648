import { cn } from '@/lib/tailwind';

const Table = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="w-full max-tablet-sm:overflow-x-auto">
      <table className="max-tablet-sm:mb-4 overflow-hidden border-separate border-spacing-0">
        <tbody
          className={cn(
            '[&>*:first-child>*:first-child]:rounded-tl-[8px]',
            '[&>*:first-child>*:last-child]:rounded-tr-[8px]',
            '[&>*:last-child>*:first-child]:rounded-bl-[8px]',
            '[&>*:last-child>*:last-child]:rounded-br-[8px]',
            '[&>*:first-child>*]:border-t',
          )}
        >
          {children}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
