import { BLOCKS, Document, Text } from '@contentful/rich-text-types';

export const removeEmptyLastParagraph = (richText: Document): Document => {
  const updatedContent = richText.content.filter((node, index) => {
    if (index !== richText.content.length - 1) {
      return true;
    }

    if (node.nodeType !== BLOCKS.PARAGRAPH) return true;

    if (
      node.content.every((node): node is Text => node.nodeType === 'text') &&
      node.content.map(node => node.value.trim()).join('').length <= 0
    ) {
      return false;
    }

    return true;
  });
  return {
    ...richText,
    content: updatedContent,
  };
};
