import {
  Options,
  RenderNode,
  RenderMark,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import Image from 'next/image';
import ImageBlock from '@/components/ImageBlock';
import { renderTextNode } from '@/components/richText/renderTextNode';
import { Link } from '../Link';
import { Table, TableCell, TableHeaderCell } from '../table';
import HeadingNode from './HeadingNode';

const RENDER_MARK_OPTIONS: RenderMark = {
  [MARKS.BOLD]: text => <strong className="font-bold">{text}</strong>,
};

export const LIST_OPTIONS: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_node, children) => children,
  [BLOCKS.LIST_ITEM]: (_node, children) => (
    <li className="m-0 p-0">{children}</li>
  ),
};

export const normalizeListItemForRichTextOptions = (
  baseOptions: Options,
): Options => {
  return {
    ...baseOptions,
    renderNode: {
      ...baseOptions.renderNode,
      [BLOCKS.LIST_ITEM]: node => {
        const normalizedChildren = documentToReactComponents(node as Document, {
          ...baseOptions,
          renderNode: {
            ...baseOptions.renderNode,
            ...LIST_OPTIONS,
          },
        });
        return normalizedChildren;
      },
    },
  };
};

const renderNodeOptions: RenderNode = {
  [BLOCKS.HEADING_1]: (node, children) => (
    <HeadingNode as="h1" fragmentId={node.data?.anchorId}>
      {children}
    </HeadingNode>
  ),
  [BLOCKS.HEADING_2]: (node, children) => (
    <HeadingNode as="h2" fragmentId={node.data?.anchorId}>
      {children}
    </HeadingNode>
  ),
  [BLOCKS.HEADING_3]: (node, children) => (
    <HeadingNode as="h3" fragmentId={node.data?.anchorId}>
      {children}
    </HeadingNode>
  ),
  [BLOCKS.HEADING_4]: (node, children) => (
    <HeadingNode as="h4" fragmentId={node.data?.anchorId}>
      {children}
    </HeadingNode>
  ),
  [BLOCKS.EMBEDDED_ASSET]: node => {
    if (
      node.data.target.file &&
      node.data.target.file.contentType.includes('video')
    ) {
      return null;
    }

    return (
      <Image
        src={node.data.target.file?.url}
        height={node.data.target.file?.details.image.height}
        width={node.data.target.file?.details.image.width}
        alt={node.data.target.title || 'RichTextEmbeddedImage'}
      />
    );
  },
  [INLINES.HYPERLINK]: (node, children) => {
    const url = node?.data?.uri;
    return (
      <Link
        href={url}
        className="font-semibold text-primaryOceanBlue hover:underline"
      >
        {children}
      </Link>
    );
  },
  [INLINES.EMBEDDED_ENTRY]: node => {
    if (!node) return null;
    const { target } = node?.data || {};

    if (target?.contentType === 'refreshImage') {
      return <ImageBlock image={target} />;
    }
    return null;
  },
  [BLOCKS.TABLE]: (_, childern) => {
    return <Table>{childern}</Table>;
  },
  [BLOCKS.TABLE_HEADER_CELL]: (_, children) => (
    <TableHeaderCell>{children}</TableHeaderCell>
  ),
  [BLOCKS.TABLE_CELL]: (_, children) => <TableCell>{children}</TableCell>,
};

export const options: Options = {
  renderNode: renderNodeOptions,
  renderText: renderTextNode,
  renderMark: RENDER_MARK_OPTIONS,
};
