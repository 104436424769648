import { ReactNode } from 'react';

const HeadingNode = ({
  as,
  fragmentId,
  children,
}: {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  fragmentId?: string;
  children: ReactNode;
}) => {
  const As = as;
  return (
    <As>
      {fragmentId ? (
        <span id={fragmentId} className="relative -top-[200px]" />
      ) : null}
      {children}
    </As>
  );
};

export default HeadingNode;
