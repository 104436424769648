import { CtaData } from '@/types';
import { cn } from '@/lib/tailwind';
import CtaAction from './behaviour/CtaAction';
import Button from './ui/Button';
import TextLink from './ui/TextLink';

const CallToAction = (props: CtaData) => {
  const { action, type, containerClassName, className } = props;
  const UiComponent = type === 'BUTTON' ? Button : TextLink;

  if (action === 'DUMMY') {
    return (
      <div className={cn(containerClassName, 'cursor-default')}>
        <UiComponent
          className={cn(className, 'hover:no-underline hover:text-baseBlack')}
          {...props}
        />
      </div>
    );
  }

  return (
    <CtaAction {...props}>
      <UiComponent {...props} />
    </CtaAction>
  );
};

export default CallToAction;
