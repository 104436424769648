import { ReactNode } from 'react';
import { CtaData } from '@/types';
import Click from './ClickAction';
import Link from './LinkAction';

const CtaAction = ({
  children,
  ...props
}: CtaData & {
  children: ReactNode;
}) => {
  const BehaviourComponent = props.action === 'NAVIGATE' ? Link : Click;
  return <BehaviourComponent {...props}>{children}</BehaviourComponent>;
};

export default CtaAction;
