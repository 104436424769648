import { CtaData, ImageData, LegacyCtaData, MediaData } from '@/types';

export const convertMediaEntryToRefreshImageEntry = (
  media: MediaData,
): ImageData => {
  return {
    ...media,
    defaultAsset: media.asset,
    contentType: 'refreshImage',
  };
};

const convertLegacyCtaAction = (
  action: LegacyCtaData['action'],
): CtaData['action'] => {
  switch (action) {
    case 'OPEN_MODAL':
      return 'OPEN_MODAL';
    default:
      return 'NAVIGATE';
  }
};

export const convertCtaEntryToRefreshCtaEntry = (
  cta: LegacyCtaData,
): CtaData => {
  return {
    ...cta,
    action: convertLegacyCtaAction(cta.action),
    label: cta.text ?? '',
    url: cta.url ?? '',
    variant: 'primary',
  };
};
